function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"code-form"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form form_content_auth",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form-fields-group"},[(_vm.showTitle)?_c('legend',{staticClass:"form-fields-group__title"},[_vm._t("default",function(){return [_vm._v(" Подтверждение аккаунта ")]})],2):_vm._e(),(_vm.current2FaType)?_c('div',{staticClass:"code-form__2fa-type"},[_vm._v(" Код подтверждения придет Вам на "+_vm._s(_vm.current2FaType)+" ")]):_vm._e(),_c('ul',{staticClass:"form-fields-list"},[_c('li',{staticClass:"form-fields-list__item"},[_c('x-form-item',{ref:"formItem",attrs:{"rules":"required","name":"Код подтверждения","label":"Введите код подтверждения из сообщения в вашей почте"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var rest = objectWithoutProperties( ref, ["errors"] );
var validationData = rest;
return [_c('x-input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"id":"confirm_сode","value":_vm.value,"name":"confirm_сode","placeholder":"Код подтверждения","failed":!!_vm.errorMessage || !!errors.length},on:{"input":_vm.onInput}},'x-input',validationData,false)),_c('span',{staticClass:"x-form-item__errors"},[_vm._v(" "+_vm._s(errors.length ? '' : _vm.errorMessage)+" ")])]}}],null,true)})],1),(_vm.showTimer)?_c('li',{staticClass:"form-fields-list__item"},[_vm._v(" Отправить код повторно можно через "),_c('count-down',{attrs:{"counter":_vm.resendTimerDuration},on:{"end":function($event){_vm.showTimer = false}}})],1):_vm._e(),(_vm.showBack)?_c('li',{staticClass:"form-fields-list__item"},[_c('back-button',{on:{"click":function($event){return _vm.$emit('back')}}})],1):_vm._e()])])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }