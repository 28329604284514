<template>
  <x-btn
    type="button"
    ghost
    prepend="chevron-left"
    @click="onClickBack"
  >
    Назад
  </x-btn>
</template>

<script>
export default {
  methods: {
    onClickBack () {
      if (!this.$listeners.click) {
        return this.$router.go(-1)
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
