<template>
  <div class="login-field">
    <x-form-item
      v-slot="validationData"
      :rules="`email|${login.phone ? '' : 'required'}`"
      name="Email"
      label="Введите ваш Email"
    >
      <x-input
        id="login-email"
        name="email"
        placeholder="Email"
        v-bind="validationData"
        :value="value.email"
        :disabled="!!value.phone"
        @input="onEmailInput"
      />
    </x-form-item>
    <template v-if="false">
      или
      <x-form-item
        v-slot="validationData"
        :rules="`digits_length:11|${login.email ? '' : 'required'}`"
        name="Номер телефона"
        label="Введите номер телефона"
      >
        <x-input
          id="login-phone"
          ref="login.phone"
          :value="value.phone"
          v-mask="'+# (###) ###-##-##'"
          name="phone"
          placeholder="Номер телефона"
          v-bind="validationData"
          :disabled="!!value.email"
          @input="onPhoneInput"
        />
      </x-form-item>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        phone: '',
        email: ''
      })
    }
  },

  data: () => ({
    login: {
      phone: '',
      email: ''
    }
  }),

  methods: {
    onPhoneInput (value) {
      this.login.phone = value
      this.$emit('input', this.login)
    },

    onEmailInput (value) {
      this.login.email = value.toLowerCase()
      this.$emit('input', this.login)
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
