<template>
  <div class="code-form">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form
        class="form form_content_auth"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <fieldset class="form-fields-group">
          <legend
            v-if="showTitle"
            class="form-fields-group__title"
          >
            <slot>
              Подтверждение аккаунта
            </slot>
          </legend>

          <div
            v-if="current2FaType"
            class="code-form__2fa-type"
          >
            Код подтверждения придет Вам на {{ current2FaType }}
          </div>

          <ul class="form-fields-list">
            <li class="form-fields-list__item">
              <x-form-item
                v-slot="{ errors, ...validationData }"
                ref="formItem"
                rules="required"
                name="Код подтверждения"
                label="Введите код подтверждения из сообщения в вашей почте"
              >
                <x-input
                  id="confirm_сode"
                  :value="value"
                  v-mask="mask"
                  name="confirm_сode"
                  placeholder="Код подтверждения"
                  v-bind="validationData"
                  :failed="!!errorMessage || !!errors.length"
                  @input="onInput"
                />
                <span class="x-form-item__errors">
                  {{ errors.length ? '' : errorMessage }}
                </span>
              </x-form-item>
            </li>

            <li
              v-if="showTimer"
              class="form-fields-list__item"
            >
              Отправить код повторно можно через
              <count-down
                :counter="resendTimerDuration"
                @end="showTimer = false"
              />
            </li>

            <li
              v-if="showBack"
              class="form-fields-list__item"
            >
              <back-button
                @click="$emit('back')"
              />
            </li>
          </ul>
        </fieldset>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import BackButton from '@/components/Interface/BackButton.vue'
import CountDown from '@/components/Common/CountDown.vue'
import { resendTimerDuration } from '@/constants/timings'
import twoFaTypes from '@/constants/twofa-types'
import '@/styles/form.styl'

const confirmCodeLength = 6

export default {
  components: {
    BackButton,
    CountDown
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    twoFaType: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    showTimer: false,
    resendTimerDuration
  }),

  computed: {
    mask () {
      return '#'.repeat(confirmCodeLength)
    },

    current2FaType () {
      return twoFaTypes.find(t => t.type === this.twoFaType)?.label
    }
  },

  watch: {
    value (code) {
      if (code.length !== confirmCodeLength) return
      this.onSubmit()
    }
  },

  methods: {
    onInput (value) {
      this.$emit('input', value)
    },

    onSubmit () {
      this.$emit('submit', this.value)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .code-form
    .form-fields-group__title {
      margin-bottom 20px
      text-align left
    }
    &__2fa-type
      margin-bottom 10px
      text-align left
      color $text-color

    &__actions
      margin-top 20px
</style>
