<template>
  <span class="counter">
    {{ time }} {{ formattedText }}
  </span>
</template>

<script>
import createCountFormatter from '@/utils/count-formatter'

export default {
  props: {
    counter: {
      type: Number,
      default: 0,
      required: true
    },
    words: {
      type: Object,
      default: () => ({
        few: 'секунд',
        one: 'секунду',
        two: 'секунды'
      })
    }
  },

  data () {
    return {
      time: this.counter
    }
  },

  computed: {
    formattedText () {
      const formatSecondsCount = createCountFormatter(this.words)
      return formatSecondsCount(this.time)
    }
  },

  mounted () {
    if (this.time) {
      const self = this
      const timer = setTimeout(function tick () {
        self.time--

        if (self.time) {
          self.timer = setTimeout(tick, 1000)
        } else {
          clearTimeout(timer)
          self.$emit('end')
        }
      }, 1000)
    }
  }
}
</script>
