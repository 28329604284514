export default Object.freeze([{
  id: 'phone',
  type: 'PHONE',
  label: 'Телефон'
}, {
  id: 'totp',
  type: 'TOTP',
  label: 'Приложение'
}, {
  id: 'email',
  type: 'EMAIL',
  label: 'Email'
}])
